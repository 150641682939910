<template>
  <v-container style="height:100%">
    <v-row style="display:flex;align-items:center;flex-direction:row;height:100%;">
      <v-col align="center" cols="12" style="display:flex;justify-content:center;">
      <router-link to="/rhythmiz" style="text-align:center;display: inline-block;">
          <div class="routerLinks">
            <img :src="require('../assets/rhythmiz/icon.jpeg')" style="width:120px;margin-top:40px;"/>
            <span>Rhythm*IZ</span>
          </div>
        </router-link>
        <router-link to="/makepm" style="text-align:center;display: inline-block;">
          <div class="routerLinks">
            <img :src="require('../assets/makepm/app/ic_launcher_foreground.png')" style="width:200px"/>
            <span>프라이빗 메일 만들기</span>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

  export default {
    name: 'Home',

    components: {
    }
  }
</script>

<style scoped>
  .routerLinks{
    display:flex;
    flex-direction: column;
    align-items:center;
    height:230px;
    width:200px;
  }

  .routerLinks span{
    
    margin-top:auto;
    margin-bottom:0px;
  }
</style>