import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/makepm',
    name: 'MakePM',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MakePM.vue')
  },
  {
    "path": '/rhythmiz',
    "name": 'RhythmIZ',
    component: () => import('../views/RhythmIZ.vue')
  },
  {
    "path": "/rhythmiz_forgot",
    "name": "RhythmIZ-Forgot",
    component: () => import("../views/RhythmIZ-Forgot.vue")
  },
  {
    "path": "/rhythmiz_register",
    "name": "RhythmIZ",
    component: () => import("../views/RhythmIZ.vue")
  },
  {
    "path": "/rhythmiz/testflight",
    "name": "RhythmIZTestflight",
    component: {
      name: "RhythmIZTestflight",
      mounted(){
        window.location.href = "https://testflight.apple.com/join/3gpsI6wD";
      }
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
